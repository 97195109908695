import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        login: false,
        bar_nom: "",
        user: "",
        id: null,
        uuid: null,
        // url:"http://localhost:3000",
        url:"",
        authorize:true,
        connection:false
    },
    mutations: {
    }
})