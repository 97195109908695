<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item
            @click="drawer = !drawer"
            v-if="this.$store.state.login"
            to="/parametres"
          >
            <v-list-item-icon>
              <v-icon>mdi-tools</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="drawer = !drawer"
            v-if="this.$store.state.login"
            to="/login"
          >
            <v-list-item-icon>
              <v-icon>mdi-table</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Liste des suggestions</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="drawer = !drawer"
            :to="'/menu/' + $route.params.bar"
            v-if="!this.$store.state.login"
          >
            <v-list-item-icon>
              <v-icon>mdi-food</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Menu</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="drawer = !drawer"
            :to="'/suggestion/' + $route.params.bar"
            v-if="!this.$store.state.login"
          >
            <v-list-item-icon>
              <v-icon>mdi-hand</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Suggestion</v-list-item-title>
          </v-list-item>
          <hr />
          <div class="pt-3">
            <v-btn
              v-if="this.$store.state.login"
              @click="logout()"
              color="black"
              class="white--text"
              small
            >
              Déconnexion</v-btn
            >
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="pink">
      <v-app-bar-nav-icon
        v-if="this.$store.state.authorize && !this.$store.state.connection"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="white--text"
        ><img src="./img/logo2.png" alt="" class="img_logo2"
      /></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <router-view class="page" />
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    logout() {
      this.$cookie.delete("login");
      this.$cookie.delete("user");

      this.$store.state.login = false;
      this.$store.state.user = "";
      this.$store.state.id = null;
      this.drawer = false

      this.$router.push("login");
    },
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
}

.page {
  margin-top: 5em;
}
.img_logo2 {
  padding-top: 0.5em;
  width: 10em;
}
.contenu {
  background-color: black;
}

</style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

body {
  font-family: "Exo", sans-serif;
}

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  z-index: 0;
  background: #2e2e33;
  width: 100%;
  height: 100%;
}

.circles {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
