import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "NotFound"
    }
  },
  {
    path: '/suggestion/:bar',
    name: 'suggestion',
    component: () => import(/* webpackChunkName: "suggestion" */ '../views/Suggestion.vue')
  },
  {
    path: '/liste-suggestion',
    name: 'ListeSuggestion',
    component: () => import(/* webpackChunkName: "listeSuggestion" */ '../views/liste_suggestion/ListeSuggestion.vue')
  },
  {
    path: '/parametres',
    name: 'parametres',
    component: () => import(/* webpackChunkName: "parametre" */ '../views/parametre/Parametre.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/menu/:bar',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/menu/Menu.vue')
  },
  {
    path: '/*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/not_found/NotFound_view.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
