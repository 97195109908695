import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import { store } from './store/store.js'
var VueCookie = require('vue-cookie');
// socket io
import * as io from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import './registerServiceWorker'

Vue.use(VueCookie);
Vue.use(store)
Vue.config.productionTip = false

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
}

if (!VueCookie.get("uuid")) {
  VueCookie.set('uuid', uuidv4(), 10)
  store.state.id = VueCookie.get("uuid");
} else {
  null
}

if (VueCookie.get("login")) {
  if (VueCookie.get("user")) {
    store.state.user = VueCookie.get("user");
    store.state.id = VueCookie.get("id");
    store.state.uuid = VueCookie.get("uuid");
    store.state.login = VueCookie.get("login");
    store.state.bar_nom = VueCookie.get("bar_nom");
  }
}

router.beforeEach(function (to, from, next) {
  if ((to.path == '/liste-suggestion' || to.path == 'liste-suggestion') && !store.state.login) {
    next({ path: '/login' })
  } else if ((to.path === "/login" || to.path === "login") && store.state.login) {
    router.push("/liste-suggestion")
  }
  else {
    next()
  }
})

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: io('/'), // options object is Optional
  })
);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
